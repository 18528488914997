/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  createMuiTheme,
  darken,
  fade,
  lighten,
} from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import { IndeterminateCheckBoxSharp } from '@material-ui/icons';
import { merge } from 'lodash';
import { Tooltip } from '@material-ui/core';

import { THEME_BL } from 'consts';
import RadioIcon from 'components/mui/RadioIcon';
import CheckIcon from 'components/mui/CheckIcon';
import StepIcon from 'components/mui/StepIcon';
import BlIcon from 'components/icons/BlIcon';

const { NODE_ENV } = process.env;

const shadows = [
  'none',
  '0px 2px 10px rgba(0, 0, 0, 0.1);',
  '0 1px 10px -5px rgba(0, 0, 0, 0.5)',
  '0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
];

const { breakpoints, ...defaultTheme } = createMuiTheme({
  breakpoints: {
    values: {
      // Sadly, but "0" breakpoint should always be named "xs"
      xs: 0,
      // This is custom breakpoint, I'm not sure how it's gonna work
      // with Grid/Container components.
      pt: 415,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1600,
    },
  },
});

const createTheme = (customTheme) => {
  const { type = 'light' } = customTheme || {};

  const rootProps = merge(
    {
      id: 'default',
      custom: {},
      spacing: 4,
    },
    customTheme,
  );

  const palette = createPalette(
    merge(
      {
        type,
        // primary: {
        //   main: '#673ab7',
        // },
        background: {
          default: '#f5f8fb',
          dark: {
            main: '#111',
          },
        },
        action: {
          disabledBackground: '#f5f8fb',
          // disabledBackground: 'rgba(0, 0, 0, 0.175)',
        },
        text: {
          primary: 'rgba(0, 0, 0, 0.75)',
          hint: 'rgba(0, 0, 0, 0.3)',
        },
        info: {
          main: '#87F9F9',
          light: '#D0FCFC',
          dark: '#009FD4',
        },
        error: {
          main: '#EE4B4B',
          light: '#FFC0C2',
        },
      },

      customTheme?.palette,
    ),
  );

  // Adding custom color for dark bg's
  palette.background.dark = {
    contrastText: palette.getContrastText(palette.background.dark.main),
    dark: darken(palette.background.dark.main, 0.2),
    light: lighten(palette.background.dark.main, 0.2),
    main: palette.background.dark.main,
    ...palette?.background?.dark,
  };

  /**
   * Typography
   */
  const fontFamilyBody = [
    ...(customTheme?.typography?.fontFamilyBody || []),
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
  ].join(',');

  const fontFamilyAccent = [
    ...(customTheme?.typography?.fontFamilyAccent || []),
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
  ].join(',');

  const typography = merge(
    {
      fontFamily: fontFamilyBody,
      fontFamilyAccent,

      h1: {
        fontFamily: fontFamilyAccent,
        fontWeight: 700,
        fontSize: '2.5rem',
        lineHeight: '1',
      },
      h2: {
        fontFamily: fontFamilyAccent,
        fontWeight: 700,
        fontSize: '1.875rem',
      },
      h3: {
        fontFamily: fontFamilyAccent,
        fontWeight: 400,
        fontSize: '1.5rem',
      },
      h4: {
        fontFamily: fontFamilyBody,
        fontWeight: 400,
        fontSize: '1.5rem',
      },
      h5: {
        fontFamily: fontFamilyAccent,
        fontWeight: 600,
        fontSize: '1.25rem',
        textTransform: 'uppercase',
      },
      h6: {
        fontFamily: fontFamilyAccent,
        fontWeight: 'bold',
        fontSize: '1rem',
        textTransform: 'uppercase',
      },
      button: {
        fontFamily: fontFamilyAccent,
        fontWeight: 600,
        textTransform: 'none',
      },
      overline: {
        fontFamily: fontFamilyAccent,
        fontWeight: 'bold',
      },
      caption: {
        lineHeight: '1.4',
      },
    },
    customTheme?.typography,
  );

  delete typography.fontFamilyBody;
  typography.fontFamily = fontFamilyBody;
  typography.fontFamilyAccent = fontFamilyAccent;

  /**
   * Other
   */
  const shape = merge(
    {
      borderRadius: 4,
    },
    customTheme?.shape,
  );

  const mixins = merge(
    {
      toolbar: { minHeight: 56 },
    },
    customTheme?.mixins,
  );

  /**
   * Setting default props for all MUI components
   */
  const props = merge(
    {
      MuiContainer: {
        // fixed: true,
        // maxWidth: false,
      },
      MuiAccordion: {
        square: true,
      },
      MuiAccordionSummary: {
        expandIcon: <BlIcon icon="arrow-down" fontSize="large" />,
      },
      MuiAppBar: {
        elevation: 0,
      },
      MuiSkeleton: {
        animation: 'wave',
      },
      MuiCardActionArea: {
        disableRipple: true,
        focusRipple: true,
      },
      MuiChip: {
        deleteIcon: <BlIcon icon="close-thin" />,
      },
      MuiLink: {
        underline: 'always',
        color: 'primary',
      },
      MuiButton: {
        variant: 'contained',
        disableElevation: true,
      },

      MuiAutocomplete: {
        popupIcon: <BlIcon icon="arrow-down" />,
      },
      MuiTextField: {
        margin: 'normal',
        variant: 'outlined',
      },
      MuiFormHelperText: {
        variant: 'outlined',
      },
      MuiOutlinedInput: {
        notched: false,
      },
      MuiCheckbox: {
        color: 'default',
        icon: <CheckIcon />,
        checkedIcon: <CheckIcon checked />,
        indeterminateIcon: <IndeterminateCheckBoxSharp fontSize="small" />,
      },
      MuiRadio: {
        color: 'default',
        icon: <RadioIcon />,
        checkedIcon: <RadioIcon checked />,
      },
      MuiSelect: {
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
        IconComponent: () => (
          <BlIcon icon="arrow-down" className="MuiSelect-icon" />
        ),
      },
      MuiSwitch: {
        color: 'primary',
      },
      MuiSlider: {
        color: 'secondary',
        ValueLabelComponent,
      },
      MuiTooltip: {
        arrow: true,
        enterTouchDelay: 0,
      },
      MuiAlert: {
        icon: false,
        variant: 'filled',
      },
      MuiDataGrid: {
        autoHeight: true,
        disableColumnMenu: true,
        disableSelectionOnClick: true,
        pageSize: 10,
        rowHeight: 60,
      },
      MuiStepper: {
        // connector: null,
      },
      MuiStepLabel: {
        StepIconComponent: StepIcon,
      },
      MuiDialogTitle: {
        TypographyProps: {
          variant: 'h5',
          component: 'h5',
        },
      },
      MuiUseMediaQuery: {
        noSsr: true,
      },
      MuiBreadcrumbs: {
        separator: (
          <BlIcon
            icon="double-arrow-right"
            className="Breadcrumbs-separatorIcon"
          />
        ),
      },
    },
    customTheme?.props,
  );

  /**
   * Overriding default styles for MUI components
   */
  const overrides = merge(
    {
      MuiAccordion: {
        root: {
          boxShadow: 'none',
          marginTop: '-1px',
          marginBottom: '-1px',

          '&:nth-child(even)': {
            backgroundColor: '#f5f8fb',
          },

          '&.Mui-expanded': {
            margin: '-1px 0',
          },

          '&:before': {
            display: 'none',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          padding: '0px 20px',
          minHeight: '53px',

          '&.Mui-expanded': {
            minHeight: '53px',
          },
        },

        content: {
          color: palette.secondary.dark,
          textTransform: 'uppercase',
          fontWeight: 700,
          fontSize: '16px',
          fontFamily: typography.fontFamilyAccent,
          margin: 0,

          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
      MuiAccordionDetails: {
        root: {
          padding: '0 20px 24px',
        },
      },

      MuiAppBar: {
        colorDefault: {
          backgroundColor: 'transparent',

          [breakpoints.up('sm')]: {
            backgroundColor: '#fff',
          },
        },
        colorSecondary: {
          backgroundColor: 'transparent',
          color: 'currentColor',

          [breakpoints.up('sm')]: {
            backgroundColor: palette.background.dark.main,
            color: palette.background.dark.contrastText,
          },
        },
      },

      MuiSelect: {
        root: {
          paddingTop: '19px',
          paddingBottom: '19px',
        },

        icon: {
          top: 'unset',
          right: '20px',
          fontSize: '1rem',
          padding: 0,
          color: palette.background.dark.main,

          [breakpoints.up('lg')]: {
            right: '16px',
          },
        },

        outlined: {
          '&$outlined': {
            paddingRight: '42px',
          },
        },
      },
      MuiMenuItem: {
        root: {
          '&$selected': {
            backgroundColor: fade(palette.primary.light, 0.4),

            '&:hover': {
              backgroundColor: fade(palette.primary.light, 0.4),
            },
          },
        },
      },
      MuiIcon: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
      MuiContainer: {
        root: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),

          [breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(3),
            paddingRight: defaultTheme.spacing(3),
          },
        },
        fixed: {
          [breakpoints.up('pt')]: {
            maxWidth: 'none',
          },
          [breakpoints.up('sm')]: {
            maxWidth: `${680 + defaultTheme.spacing(4) * 2}px`,
          },
          [breakpoints.up('md')]: {
            maxWidth: `${920 + defaultTheme.spacing(4) * 2}px`,
          },
          [breakpoints.up('lg')]: {
            maxWidth: `${1146 + defaultTheme.spacing(4) * 2}px`,
          },
        },
        maxWidthLg: {
          [breakpoints.up('lg')]: {
            maxWidth: `${1146 + defaultTheme.spacing(4) * 2}px`,
          },
        },
      },

      MuiIconButton: {
        root: {
          color: palette.background.dark.main,
        },
      },

      MuiButton: {
        root: {
          fontSize: '0.875rem',
          padding: '9px 24px',
          // minWidth: '150px',
        },

        text: {
          paddingTop: '8px',
          paddingBottom: '8px',
          color: palette.background.dark.main,
        },

        contained: {
          backgroundColor: palette.background.dark.main,
          color: palette.background.dark.contrastText,
          '&$disabled': {
            backgroundColor: '#B4B4B4',
            color: '#fff',
          },
          '&:hover': {
            backgroundColor: palette.background.dark.light,

            '@media (hover: none)': {
              backgroundColor: palette.background.dark.main,
            },
          },
        },

        outlined: {
          padding: '7px 22px',
          borderWidth: '2px',
          borderColor: palette.background.dark.main,
          color: palette.background.dark.main,

          '&$disabled': {
            borderWidth: '2px',
            borderColor: 'currentColor',
          },
          '&:hover': {
            border: `2px solid ${palette.background.dark.main}`,
          },
        },

        outlinedPrimary: {
          border: `2px solid ${palette.primary.main}`,

          '&:hover': {
            borderWidth: '2px',
          },
        },
        outlinedSecondary: {
          border: `2px solid ${palette.secondary.main}`,

          '&:hover': {
            borderWidth: '2px',
          },
        },

        outlinedSizeLarge: {
          padding: '8px 30px',
          fontSize: '1rem',
        },
        containedSizeLarge: {
          padding: '10px 32px',
          fontSize: '1rem',
        },
        containedSizeSmall: {
          padding: '5.5px 14px',
          fontSize: '0.75rem',
        },
        textSizeLarge: {
          padding: '11px 15px',
          fontSize: '1rem',
        },
        textSizeSmall: {
          borderRadius: '100rem',
          paddingLeft: '8px',
          paddingRight: '8px',
        },

        startIcon: {
          marginRight: '5px',
        },

        endIcon: {
          marginLeft: '5px',
        },

        iconSizeSmall: {
          '& .BlIcon-root': {
            fontSize: '14px',
          },
        },

        iconSizeMedium: {
          '& .BlIcon-root': {
            fontSize: '16px',
          },
        },

        iconSizeLarge: {
          '& .BlIcon-root': {
            fontSize: '20px',
          },
        },
      },

      MuiToggleButtonGroup: {
        groupedHorizontal: {
          '&:not(:first-child)': {
            borderLeft: null,
          },
        },
      },
      MuiTableCell: {
        root: {
          color: palette.text.primary,
          lineHeight: '1.5',
          borderBottom: 'none',
          fontSize: '1rem',

          '&.MuiTableCell-head': {
            fontSize: '0.75rem',
            color: palette.background.dark.main,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          },
        },
      },
      MuiTableRow: {
        root: {
          backgroundColor: '#fff',
        },
      },
      MuiBreadcrumbs: {
        root: {
          fontSize: '12px',
        },
        li: {
          lineHeight: 1,
          padding: '3px 0 6px',
          '& a': {
            color: palette.text.secondary,
          },
          '&:last-child a': {
            color: palette.background.dark.main,
          },
        },
        separator: {
          color: palette.background.dark.light,
          '& .Breadcrumbs-separatorIcon': {
            width: '11px',
            fontSize: '11px',
          },
        },
      },

      MuiToggleButton: {
        root: {
          backgroundColor: '#fff',
          color: palette.text.secondary,

          '&$selected': {
            backgroundColor: palette.background.dark.main,
            color: palette.background.dark.contrastText,

            '&:hover': {
              backgroundColor: palette.background.dark.light,
            },
          },
        },
      },

      MuiLink: {
        root: {
          fontWeight: 600,
          transition: '0.2s ease',

          '&:hover': {
            opacity: 0.8,
            textDecoration: 'none',
          },
        },
      },

      MuiChip: {
        root: {
          height: '36px',
          backgroundColor: '#f5f8fb',
          padding: '4px',
          maxWidth: '100%',
          fontSize: '1rem',
          lineHeight: '2rem',
          borderRadius: '100rem',
        },
        deleteIcon: {
          color: '#3d3d3d',

          '&.MuiIcon-root': {
            fontSize: '0.75rem',
            marginLeft: '-2px',
          },
        },
        label: {
          lineHeight: 1.8,
        },
      },

      MuiFormLabel: {
        root: {
          '&$focused': {
            color: palette.background.dark.main,
          },
          '&$error': {
            color: null,
          },
        },
      },

      MuiAutocomplete: {
        root: {
          '& legend': {
            maxWidth: 0,
          },
        },
        popupIndicator: {
          marginRight: '4px',
          padding: '4px',
        },

        paper: {
          margin: 0,
          borderRadius: 0,
        },

        option: {
          '&[aria-selected="true"], &[data-focus="true"]': {
            backgroundColor: fade(palette.primary.light, 0.4),

            // '&:hover': {
            //   backgroundColor: fade(palette.primary.light, 0.4),
            // },
          },
        },
      },

      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
          borderWidth: '1px',
          borderColor: palette.text.hint,
          backgroundColor: '#fff',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: palette.text.hint,
          },

          '&$focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: palette.background.dark.main,
            },

            '& .MuiTextFieldDivider-root': {
              color: palette.background.dark.main,
            },
          },

          '&$focused$error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: palette.error.main,
            },

            '& .MuiTextFieldDivider-root': {
              color: palette.error.main,
            },
          },

          '&$error': {
            '& .MuiTextFieldDivider-root': {
              color: palette.error.main,
            },
          },
          '&$disabled': {
            backgroundColor: '#F5F8FB',
            color: palette.text.hint,
          },

          '&:hover': {
            '& .MuiTextFieldDivider-root': {
              color: palette.text.primary,
            },
          },
        },
        input: {
          height: '3.75rem',
          lineHeight: '1.4',
          padding: '19px 20px',
          boxSizing: 'border-box',
        },

        adornedEnd: {
          paddingRight: '8px',
          alignItems: 'stretch',
        },

        multiline: {
          paddingLeft: '20px',
          paddingTop: '23px',
          paddingBottom: '15px',
        },
      },

      MuiInputLabel: {
        outlined: {
          color: palette.text.hint,
          textTransform: 'capitalize',
          transform: 'translate(20px, 22px) scale(1)',

          '&$shrink': {
            transform: 'translate(20px, 8px) scale(0.75)',
            fontWeight: 'bold',
            textTransform: 'lowercase',
            color: palette.background.dark.main,
          },
          '&$disabled': {
            color: palette.text.hint,
          },

          '& + .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
            '& .MuiOutlinedInput-input': {
              paddingTop: '23px',
              paddingBottom: '15px',
            },
          },
        },
      },

      MuiInputAdornment: {
        root: {
          maxHeight: 'none',
          height: 'auto',
        },
      },

      MuiFormHelperText: {
        root: {
          lineHeight: 1.2,
          marginTop: '5px',

          '&$error': {
            font: `700 0.75rem ${typography.fontFamily}`,
          },
        },
        contained: {
          marginLeft: 0,
        },
      },

      MuiListSubheader: {
        root: {
          textTransform: 'uppercase',
          fontSize: '0.75rem',
        },
      },
      MuiListItemIcon: {
        root: {
          width: '32px',
          marginRight: '8px',
          minWidth: 'none',
        },
      },
      MuiAlert: {
        root: {
          position: 'relative',
          boxShadow: shadows[1],
          borderRadius: '0',
          textAlign: 'left',
          '& .MuiTypography-h6': {
            fontWeight: 400,
          },
        },
        filledSuccess: {
          color: palette.background.dark.main,
          backgroundColor: palette.success.light,
        },
        filledInfo: {
          color: palette.getContrastText(palette.info.light),
          backgroundColor: palette.info.light,
        },
        filledWarning: {
          color: palette.getContrastText(palette.warning.light),
          backgroundColor: palette.warning.light,
        },
        filledError: {
          color: palette.getContrastText(palette.error.light),
          backgroundColor: palette.error.light,
        },

        action: {
          flexShrink: 0,
        },
      },

      MuiDialog: {
        scrollBody: {
          '&::after': {
            display: 'none',
          },
        },

        paperScrollBody: {
          display: 'inline-flex',
          flexDirection: 'column',

          '& .MuiDialogContent-root': {
            overflowY: 'visible',
          },
        },
      },

      MuiDialogTitle: {
        root: {
          paddingRight: '54px',
          color: palette.background.dark.main,
        },
        caption: {
          color: '#3d3d3d',
        },
      },

      MuiDialogContent: {
        root: {
          paddingBottom: '30px',
        },
      },

      MuiDialogActions: {
        root: {
          padding: '8px 24px 24px',
        },
      },

      MuiSwitch: {
        root: {
          width: '64px',
          height: '38px',
          padding: '7px',
        },

        track: {
          borderRadius: '100rem',
        },

        thumb: {
          width: '18px',
          height: '18px',
          backgroundColor: '#fff',
        },

        switchBase: {
          padding: '10px',

          '&$checked': {
            transform: 'translateX(26px)',
          },

          '&$checked + $track': {
            backgroundColor: palette.background.dark.main,
            opacity: 1,
          },

          '&$disabled + $track': {
            opacity: 0.12,
          },
        },

        colorPrimary: {
          '&$checked + $track': {
            backgroundColor: palette.primary.light,
          },
        },
        colorSecondary: {
          '&$checked + $track': {
            backgroundColor: palette.secondary.light,
          },
        },
      },

      MuiCheckbox: {
        root: {
          color: fade(palette.background.dark.main, 0.6),
          transition: 'color 0.2s ease',

          '&$checked': {
            color: palette.background.dark.main,
          },

          '&$disabled': {
            color: palette.action.disabled,
          },

          '&.MuiCheckbox-alignStart': {
            alignSelf: 'flex-start',
            marginTop: '-8px',
          },
        },
      },

      MuiSwitchChip: {
        root: {
          '&$checked': {
            backgroundColor: fade(palette.primary.light, 0.875),

            ...(() => {
              if (rootProps?.id !== THEME_BL) return {};

              return {
                backgroundColor: palette.secondary.light,
              };
            })(),
          },
        },
      },

      MuiRadio: {
        root: {
          color: palette.background.dark.main,

          '& .MuiSvgIcon-root:first-child': {
            opacity: 0.6,
          },

          '&$disabled .MuiSvgIcon-root:first-child': {
            opacity: 1,
          },

          '& + .MuiFormControlLabel-label': {
            borderRadius: '100rem',
            padding: '0.25rem 0.9rem',
            marginLeft: '-0.25rem',
          },

          '&$checked + .MuiFormControlLabel-label': {
            backgroundColor: fade(palette.primary.light, 0.875),
            fontWeight: 600,

            ...(() => {
              if (rootProps?.id !== THEME_BL) return {};

              return {
                backgroundColor: palette.secondary.light,
              };
            })(),
          },
          '&$checked + .MuiFormControlLabel-label$disabled': {
            backgroundColor: '#f5f8fb',
          },
        },
      },

      MuiSlider: {
        root: {
          height: '7px',
          padding: '13px 0',

          '@media(pointer: coarse)': {
            padding: '13px 0',
          },

          '& .MuiTooltip-popper': {
            zIndex: 10,
          },

          '& .MuiTooltip-tooltip': {
            fontSize: '1rem',
            fontFamily: fontFamilyAccent,
            fontWeight: 600,
            padding: '4px 6px 2px',
          },
        },

        colorSecondary: {
          color: palette.background.dark.main,

          '& $thumb': {
            backgroundColor:
              rootProps.id === THEME_BL
                ? palette.secondary.main
                : palette.primary.main,
          },
        },

        rail: {
          height: 'inherit',
          color: '#B4B4B4',
          opacity: 1,
        },

        track: {
          height: 'inherit',
        },

        thumb: {
          width: '28px',
          height: '28px',
          marginTop: '-10px',
          marginLeft: '-14px',
          border: '8px solid currentColor',
          backgroundColor: palette.primary.light,
        },

        marked: {
          marginBottom: '30px',
        },

        markLabel: {
          top: '40px',
          fontSize: '1rem',
        },

        mark: {
          height: '16px',
          top: '9px',
          backgroundColor: '#B4B4B4',
          opacity: '1',
          borderRadius: 0,
        },

        markActive: {
          backgroundColor: 'currentColor',
          opacity: 1,
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '0.875rem',
          fontWeight: 'normal',
          backgroundColor: '#fff',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.12)',
          color: palette.background.dark.main,
          lineHeight: '1.2',
          padding: '10px 22px',
          border: `0.5px solid ${palette.action.disabledBackground}`,

          // borderRadius: '6px',
        },
        touch: {
          padding: '10px 22px',
          fontSize: '1rem',
        },
        arrow: {
          color: '#fff',

          '&::before': {
            position: 'relative',
            border: `0.5px solid ${palette.action.disabledBackground}`,
            zIndex: 1,
          },
        },
      },

      MuiDataGrid: {
        root: {
          border: 'none',
          fontSize: '1rem',
          maxWidth: '100%',

          [`
          & .MuiDataGrid-columnHeader:focus, 
          & .MuiDataGrid-cell:focus, 
          & .MuiDataGrid-columnHeader:focus-within, 
          & .MuiDataGrid-cell:focus-within
          `]: {
            outline: 'none',
          },

          '& .MuiDataGrid-columnHeader': {
            '&:first-child:not(.MuiDataGrid-columnHeaderCheckbox)': {
              paddingLeft: '30px',
            },

            '&:last-child': {
              paddingRight: '30px',
            },
          },

          '& .MuiDataGrid-cell': {
            borderBottom: 'none',

            '&:first-child:not(.MuiDataGrid-cellCheckbox)': {
              paddingLeft: '30px',
            },

            '&:last-child': {
              paddingRight: '30px',
            },
          },

          '& .MuiDataGrid-main': {
            paddingBottom: '1rem',
            backgroundColor: palette.background.paper,
            boxShadow: shadows[1],
            borderRadius: 'inherit',
          },

          '& .MuiDataGrid-columnsContainer': {
            paddingTop: '1rem',
            borderBottom: 'none',
          },

          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },

          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '0.75rem',
            fontFamily: typography.fontFamilyAccent,
            color: palette.background.dark.main,
            textTransform: 'uppercase',
            fontWeight: 700,
          },

          '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0,
          },

          '& .MuiDataGrid-iconButtonContainer': {
            display: 'flex',
            alignItems: 'center',
          },

          '& .MuiDataGrid-footerContainer': {
            marginTop: '30px',
          },

          '& .MuiTablePagination-root': {
            marginLeft: 'auto',
          },
        },
      },
      MuiTablePagination: {
        actions: {
          marginLeft: '0.5rem',

          '& .MuiIconButton-root': {
            background: '#fff',
            borderRadius: '4px',
            border: '1px solid #b4b4b4',
            borderLeftWidth: 0,

            '&:not(:first-child)': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },

            '&:not(:last-child)': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderLeftWidth: '1px',
            },
          },
        },
      },

      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          minWidth: '260px',
        },
      },

      MuiPickersBasePicker: {
        pickerView: {
          minWidth: ' ',
          minHeight: 0,
        },
      },

      MuiPickersCalendar: {
        transitionContainer: {
          marginTop: 0,
          minHeight: '200px',
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          padding: '8px',
          margin: '2px',
          borderRadius: shape.borderRadius,
        },

        daysHeader: {
          borderBottom: '1px solid rgba(125,125,125, 0.25)',
          paddingBottom: '0.5rem',
          marginBottom: '2px',
          marginTop: '-8px',
        },

        dayLabel: {
          width: '32px',
        },

        transitionContainer: {
          '& .MuiTypography-body1': {
            textTransform: 'uppercase',
            color: palette.background.dark.main,
            fontFamily: fontFamilyAccent,
            fontWeight: 'bold',
          },
        },
      },
      MuiPickersDay: {
        day: {
          borderRadius: 0,
          width: '28px',
          height: '28px',
          margin: '2px 4px',

          '& .MuiTypography-body2': {
            fontSize: '14px',
            fontWeight: 500,
          },
        },

        daySelected: {
          backgroundColor: palette.background.dark.main,

          '&:hover': {
            backgroundColor: palette.background.dark.light,
          },
        },
      },

      MuiStepper: {
        root: {
          background: 'none',
          border: 'none',
          padding: '20px 0',
        },
        horizontal: {
          justifyContent: 'space-between',

          '& .MuiStepLabel-labelContainer': {
            display: 'none',
          },

          [breakpoints.up('md')]: {
            '& .MuiStepConnector-root': {
              display: 'none',
            },

            '& .MuiStepLabel-labelContainer': {
              display: 'inline-flex',
            },
          },
        },
      },
      MuiStep: {
        root: {
          [breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            flexBasis: 0,
            backgroundColor: '#fff',
            border: `1px solid ${palette.action.disabledBackground}`,
            height: '50px',
            marginRight: '2px',

            '&:last-child': {
              marginRight: '0',
            },
          },
        },
        horizontal: {
          paddingLeft: null,
          paddingRight: null,

          [breakpoints.up('md')]: {
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        },
      },

      MuiStepLabel: {
        label: {
          fontWeight: 'bold',
          fontSize: '1rem',

          '&$completed': {
            fontWeight: null,
          },

          '&$active': {
            fontWeight: null,
          },

          [breakpoints.up('md')]: {
            fontSize: '0.875rem',
          },
        },
        iconContainer: {
          paddingRight: null,
        },
        labelContainer: {
          paddingLeft: '12px',
        },
      },

      MuiStepButton: {
        root: {
          boxSizing: null,
          margin: '-0.5rem -1rem',
          padding: '0.5rem 1rem',
          width: 'calc(100% + 2rem)',
          justifyContent: 'flex-start',
        },
      },

      MuiStepConnector: {
        root: {
          '&$disabled': {
            opacity: 0.375,
          },
        },

        line: {
          borderColor: 'currentColor',
        },

        lineHorizontal: {
          borderTopWidth: '0.5rem',
        },
      },
    },
    customTheme?.overrides,
  );

  const theme = createMuiTheme({
    ...rootProps,
    breakpoints: { values: breakpoints.values },
    palette,
    typography,
    shape,
    mixins,
    props,
    overrides,
  });

  shadows.forEach((shadow, index) => {
    theme.shadows[index] = shadow;
  });

  if (NODE_ENV === 'development') {
    window.theme = theme;
  }

  return theme;
};

const ValueLabelPopperProps = {
  disablePortal: true,
};

function ValueLabelComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      PopperProps={ValueLabelPopperProps}
    >
      {children}
    </Tooltip>
  );
}

export default createTheme;
